<template>
<div class='projectList w1200'>
           <div class='projectDetails' >
           <div class='sxxq w1200'>
               <h3>{{datas.titlePrimary}}</h3>
                  <div class='h4 l-start'>
                        <div class='time'>{{datas.createTime}}</div>
                        <!-- <div class='xian'></div> -->
                        <!-- <div class='num'>123</div> -->
                  </div>
                  <!-- <div class='txt' id='txt' contenteditable="true"></div> -->
                  <div v-html="datas.titleDetails?datas.titleDetails:''"></div>

                <div class='returnList'>
                    <div class='returnList-btn b' @click='returnListFn'>返回列表</div>
                </div>
                <!-- <div class='sxxq-bom'>
                    <div class='sxxq-list l-between b'>
                        <div>上一条  sssssss</div>
                        <div>2021-08-10</div>
                    </div>
                    <div class='sxxq-list l-between b'>
                        <div>下一条  sssssss</div>
                        <div>2021-08-03</div>
                    </div>
                </div> -->
           </div>
                  


               </div>
      </div>
       
</template>

<script>
export default {
    props:['isshow','datas'],
    data () {
        return {
            datas:{}
        }
    },
    watch: {
      datas(val) {
          setTimeout(()=>{
                // if(this.datas.titleDetails)  $("#txt").html(this.datas.titleDetails)
          },100)
        
       
      }
    },

    mounted () {  
        let  u= sessionStorage.getItem("dataDetails") 
         if(u){
             this.datas=JSON.parse(u)
         }
    },
    methods: {
        returnListFn(){
            // this.$parent[this.isshow]=false
            sessionStorage.setItem("isshow","否")
            setTimeout(()=>{
                $(".boxb").animate({scrollTop: 0},200);
            },200)
            let  u  = this.$route.query.u
            this.$router.push({
                path: '/news',
                query:{
                     pageNo: this.$route.query.pageNo,
                    ids: this.$route.query.ids,
                    titleType:this.$route.query.titleType
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.w1200{
  width:936px;
  margin:0 auto;
}
.projectList{
    padding-bottom:50px;
}
    .projectDetails{
        margin:60px 0 20px 0;
        
    }
    .sxxq{
                background:#fff;
                h3{
                    min-height: 37px;
                    font-size: 28px;
                    color: #333333;
                    line-height: 37px;
                    text-align: center;
                }
                .txt{
                    font-size: 14px;
                    color: #666666;
                    line-height: 30px;
                    padding:20px;
                }
                .h4{
                    font-size: 12px;
                    color: #999999;
                    margin:28px 0 16px 0;
                    .time{
                        background:url('../../assets/images/projectData_6.png') no-repeat left center;
                        background-size:13px 13px;
                        padding-left:18px;
                        margin: 15px auto;
                        margin-bottom: 20px;
                    }
                    .xian{
                        width: 2px;
                        height: 15px;
                        opacity: 0.27;
                        background:  rgba(151, 151, 151, .27);
                        margin:0 14px;
                        position: relative;
                        top:2px;
                    }
                    .num{
                        background:url('../../assets/images/projectData_7.png') no-repeat left center;
                        background-size:14px 14px;
                        padding-left:18px;
                    }
                }
                .returnList{
                    width: 936px;
                    height: 47px;
                    background: #F9F9F9;
                    border-radius: 4px;
                    margin:0 auto;
                    margin-top:40px;
                    text-align: right;
                    .returnList-btn{
                        display: inline-block;
                        font-size: 12px;
                        color: #666666;
                        line-height:47px;
                        background:url('../../assets/images/projectData_5.png') no-repeat left center;
                        background-size:13px 14px;
                        padding-left:20px;
                        text-align: right;
                        padding-right:20px;
                    }
                }
            }

</style>
<style lang='less'>
.projectDetails{
      img{
            width:700px;
        }
        
    }

</style>